export const DownloadIcon = (props) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#000"
      d="M5.625 15a.75.75 0 0 0-1.5 0h1.5Zm-.75 1h-.75.75Zm14.4-1a.75.75 0 0 0-1.5 0h1.5Zm-8.166.539a.75.75 0 0 0 1.182.922l-1.182-.922Zm5.082-4.078a.75.75 0 0 0-1.182-.922l1.182.922Zm-5.082 5a.75.75 0 0 0 1.182-.922l-1.182.922ZM8.39 10.54a.75.75 0 0 0-1.182.922l1.182-.922ZM10.95 16a.75.75 0 0 0 1.5 0h-1.5Zm1.5-11a.75.75 0 0 0-1.5 0h1.5ZM4.125 15v1h1.5v-1h-1.5Zm0 1c0 2.053 1.628 3.75 3.675 3.75v-1.5c-1.183 0-2.175-.99-2.175-2.25h-1.5ZM7.8 19.75h7.8v-1.5H7.8v1.5Zm7.8 0c2.047 0 3.675-1.697 3.675-3.75h-1.5c0 1.26-.992 2.25-2.175 2.25v1.5ZM19.275 16v-1h-1.5v1h1.5Zm-6.984.461 3.9-5-1.182-.922-3.9 5 1.182.922Zm0-.922-3.9-5-1.182.922 3.9 5 1.182-.922Zm.159.461V5h-1.5v11h1.5Z"
    />
  </svg>
  );
};

export const DeleteIcon = (props) => {
  return(
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M10 11v6M14 11v6M4 7h16M6 7h12v11a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V7ZM9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2H9V5Z" />
    </g>
  </svg>
  )
}

export const ActiveIcon = (props) => {
return(
<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={20}
    height={20}
    viewBox="0 0 206.108 206.108"
    {...props}
  >
    <path d="M152.774 69.886H30.728l24.97-24.97a9 9 0 0 0 0-12.728 9 9 0 0 0-12.729 0L2.636 72.523a9 9 0 0 0 0 12.728l40.333 40.333a8.97 8.97 0 0 0 6.364 2.636 9 9 0 0 0 6.364-15.364l-24.97-24.97h122.046c19.483 0 35.334 15.851 35.334 35.334s-15.851 35.334-35.334 35.334H78.531c-4.971 0-9 4.029-9 9s4.029 9 9 9h74.242c29.408 0 53.334-23.926 53.334-53.334s-23.925-53.334-53.333-53.334z" />
  </svg>
)
}

export const DocIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 64 64" {...props} width={25}
    height={25}>
    <g fillRule="evenodd">
      <path
        fill="#107cad"
        d="M5.11 0A5.07 5.07 0 0 0 0 5v53.88A5.07 5.07 0 0 0 5.11 64h45.78A5.07 5.07 0 0 0 56 58.88v-38.6L37.06 0z"
      />
      <path
        fill="#084968"
        d="M56 20.35v1H43.18s-6.31-1.26-6.13-6.71c0 0 .21 5.71 6 5.71z"
      />
      <path
        fill="#90d0fe"
        d="M37.07 0v14.56a5.78 5.78 0 0 0 6.11 5.79H56z"
        opacity={0.5}
      />
    </g>
    <path
      fill="#fff"
      d="M14.24 53.86h-3a1.08 1.08 0 0 1-1.08-1.08v-9.85a1.08 1.08 0 0 1 1.08-1.08h3a6 6 0 1 1 0 12zm0-10.67h-2.61v9.34h2.61a4.41 4.41 0 0 0 4.61-4.66 4.38 4.38 0 0 0-4.61-4.68zm14.42 10.89a5.86 5.86 0 0 1-6-6.21 6 6 0 1 1 11.92 0 5.87 5.87 0 0 1-5.92 6.21zm0-11.09c-2.7 0-4.41 2.07-4.41 4.88s1.71 4.88 4.41 4.88 4.41-2.09 4.41-4.88S31.35 43 28.66 43zm18.45.38a.75.75 0 0 1 .2.52.71.71 0 0 1-.7.72.64.64 0 0 1-.51-.24 4.06 4.06 0 0 0-3-1.38 4.61 4.61 0 0 0-4.63 4.88 4.63 4.63 0 0 0 4.63 4.88 4 4 0 0 0 3-1.37.7.7 0 0 1 .51-.24.72.72 0 0 1 .7.74.78.78 0 0 1-.2.51 5.33 5.33 0 0 1-4 1.69 6.22 6.22 0 0 1 0-12.43 5.26 5.26 0 0 1 4 1.72z"
    />
  </svg>
  )
}

export const ExcelIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 64 64" {...props} width={25}
    height={25}>
    <path
      fill="#45B058"
      fillRule="evenodd"
      d="M5.112.006A5.074 5.074 0 0 0 .039 5.08v53.841a5.073 5.073 0 0 0 5.073 5.074h45.774a5.074 5.074 0 0 0 5.074-5.074V20.316L37.058.006H5.112z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M19.429 53.938a.64.64 0 0 1-.54-.27l-3.728-4.97-3.745 4.97a.641.641 0 0 1-.54.27.71.71 0 0 1-.72-.72c0-.144.035-.306.144-.432l3.89-5.131-3.619-4.826a.722.722 0 0 1-.145-.414c0-.342.288-.72.721-.72.216 0 .432.108.576.288l3.438 4.628 3.438-4.646a.643.643 0 0 1 .541-.27c.378 0 .738.306.738.72a.695.695 0 0 1-.127.414l-3.619 4.808 3.891 5.149a.7.7 0 0 1 .125.414c0 .396-.324.738-.719.738zm9.989-.126h-5.455a1.083 1.083 0 0 1-1.081-1.08V42.415c0-.396.324-.72.774-.72.396 0 .721.324.721.72V52.48h5.041c.359 0 .648.288.648.648 0 .396-.289.684-.648.684zm6.982.216c-1.782 0-3.188-.594-4.213-1.495a.71.71 0 0 1-.234-.54c0-.36.27-.756.702-.756.144 0 .306.036.433.144.828.738 1.98 1.314 3.367 1.314 2.143 0 2.826-1.152 2.826-2.071 0-3.097-7.111-1.386-7.111-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.468 3.853 1.278a.73.73 0 0 1 .253.54c0 .36-.307.72-.703.72a.676.676 0 0 1-.432-.162c-.883-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636 0 1.748-1.188 3.53-4.43 3.53z"
    />
    <path
      fill="#349C42"
      fillRule="evenodd"
      d="M55.953 20.352v1H43.152s-6.312-1.26-6.127-6.707c0 0 .207 5.707 6.002 5.707h12.926z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M37.049 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.801L37.049 0z"
      clipRule="evenodd"
      opacity={0.5}
    />
  </svg>
  )
}

export const ImageIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 64 64" {...props} width={25}
    height={25}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#49C9A7"
        d="M5.125.042A5.074 5.074 0 0 0 .053 5.116v53.841a5.072 5.072 0 0 0 5.072 5.073H50.9a5.074 5.074 0 0 0 5.074-5.073V20.353L37.07.042H5.125z"
      />
      <path
        fill="#37BB91"
        d="M55.977 20.352v1H43.178s-6.312-1.26-6.129-6.707c0 0 .208 5.707 6.004 5.707h12.924z"
      />
      <path
        fill="#fff"
        d="M37.074 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.799L37.074 0z"
        opacity={0.5}
      />
      <path
        fill="#fff"
        d="M10.119 53.739V32.835h20.906v20.904H10.119zm18.799-18.843H12.227v12.6h16.691v-12.6zm-9.583 8.384 3.909-5.256 1.207 2.123 1.395-.434.984 5.631H13.748l3.496-3.32 2.091 1.256zm-3.856-3.64c-.91 0-1.649-.688-1.649-1.538 0-.849.739-1.538 1.649-1.538.912 0 1.65.689 1.65 1.538 0 .85-.738 1.538-1.65 1.538z"
      />
    </g>
  </svg>
  )
}

export const PdfIcon = (props) => {
  return(
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={25}
    height={25}
    viewBox="0 0 56 64"
    {...props}
  >
    <path
      fill="#8C181A"
      d="M5.1 0C2.3 0 0 2.3 0 5.1v53.8C0 61.7 2.3 64 5.1 64h45.8c2.8 0 5.1-2.3 5.1-5.1V20.3L37.1 0h-32z"
    />
    <path
      fill="#6B0D12"
      d="M56 20.4v1H43.2s-6.3-1.3-6.1-6.7c0 0 .2 5.7 6 5.7H56z"
    />
    <path
      fill="#FFF"
      d="M37.1 0v14.6c0 1.7 1.1 5.8 6.1 5.8H56L37.1 0z"
      opacity={0.5}
    />
    <path
      fill="#FFF"
      d="M14.9 49h-3.3v4.1c0 .4-.3.7-.8.7-.4 0-.7-.3-.7-.7V42.9c0-.6.5-1.1 1.1-1.1h3.7c2.4 0 3.8 1.7 3.8 3.6 0 2-1.4 3.6-3.8 3.6zm-.1-5.9h-3.2v4.6h3.2c1.4 0 2.4-.9 2.4-2.3s-1-2.3-2.4-2.3zm10.4 10.7h-3c-.6 0-1.1-.5-1.1-1.1v-9.8c0-.6.5-1.1 1.1-1.1h3c3.7 0 6.2 2.6 6.2 6s-2.4 6-6.2 6zm0-10.7h-2.6v9.3h2.6c2.9 0 4.6-2.1 4.6-4.7.1-2.5-1.6-4.6-4.6-4.6zm16.3 0h-5.8V47h5.7c.4 0 .6.3.6.7s-.3.6-.6.6h-5.7v4.8c0 .4-.3.7-.8.7-.4 0-.7-.3-.7-.7V42.9c0-.6.5-1.1 1.1-1.1h6.2c.4 0 .6.3.6.7.1.3-.2.6-.6.6z"
    />
  </svg>
  )
}

export const FileIcon = (props) => {
  return(
    <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={20}
    height={20}
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 3H8.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C5 4.52 5 5.08 5 6.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C6.52 21 7.08 21 8.2 21H12m1-18 6 6m-6-6v4.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C13.76 9 14.04 9 14.6 9H19m0 0v2m.269 8.269L21 21m-1-3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
    />
  </svg>
  )
}

export const BlockIcon = (props) => {
  return(
    <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    fill="#000"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={20}
    height={20}
    {...props}
  >
    <g id="SVGRepo_iconCarrier">
      <defs>
        <style>
          {
            ".cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.91px}"
          }
        </style>
      </defs>
      <circle cx={12} cy={12} r={10.5} className="cls-1" />
      <path d="M19.64 4.36 4.36 19.64" className="cls-1" />
    </g>
  </svg>
  )
}

export const EditIcon = (props) => {
  return(
    <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={20}
    height={20}
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6m-1.586-3.586L19.5 7.328A2 2 0 0 0 16.672 4.5l-1.086 1.086m2.828 2.828-6.036 6.037a2 2 0 0 1-1.022.546l-2.942.589.589-2.942a2 2 0 0 1 .547-1.022l6.036-6.036m2.828 2.828-2.828-2.828"
    />
  </svg>
  )
}